import React, { InputHTMLAttributes } from "react";
import { classNames } from "../../utils/helper";
import { style } from "../styles/styles";
import { Tooltip } from "./Tooltip";

type FieldProps = InputHTMLAttributes<HTMLInputElement> & {
  controlId: string;
  label?: string;
  onChange(e: React.ChangeEvent<HTMLInputElement>): void;
  variant: "primary";
  validationError?: string;
  // overwrite to exclude number
  type: "email" | "text";
  buttonLeft?: React.ReactNode;
  buttonRight?: React.ReactNode;
  additionalInputClasses?: string;
  tooltip?: string;
};

const inputClasses =
  "bg-gray-100 rounded appearance-none border-1 border-gray-100 w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-primary-500 focus:border-primary-500 focus:bg-white transition-all";

export const Field: React.FC<FieldProps> = ({
  controlId,
  label,
  onChange,
  validationError,
  buttonLeft,
  buttonRight,
  additionalInputClasses,
  tooltip,
  ...props
}) => {
  return (
    <div className={`flex ${label ? "flex-col" : "flex-row"}`}>
      {label && (
        <div className="flex items-center mb-1">
          <label htmlFor={controlId} className={`${style.label} block `}>
            {label}
          </label>
          {Boolean(tooltip) && <Tooltip>{tooltip}</Tooltip>}
        </div>
      )}
      {buttonLeft}
      <div className="relative flex flex-grow text-center stretch focus-within:z-10">
        <input
          id={controlId}
          name={controlId}
          className={classNames(
            validationError ? "border-primary-500" : "",
            inputClasses,
            additionalInputClasses || "",
          )}
          onChange={onChange}
          {...props}
        />
        {validationError ? <p className="mt-2 text-xs text-primary-500">{validationError}</p> : ""}
      </div>
      {buttonRight}
    </div>
  );
};
