import fetch from "isomorphic-fetch";
import { LoginResponse } from "../model/LoginResponse";

export type HttpMethod = "GET" | "POST" | "PUT" | "DELETE";

export class UnauthorizedError extends Error {
  public constructor() {
    super("unauthorized");
  }
}
export class NotFoundError extends Error {
  public constructor() {
    super("notFound");
  }
}
export class ConflictError extends Error {
  public constructor() {
    super("conflict");
  }
}

/**
 * Thanks to Daniel Faber 🚀
 */
class ApiClientAnonymous {
  public async login(email: string, ordernumber: string): Promise<LoginResponse | string> {
    const res = (await this.jsonRequest("POST", "/api/auth/login", {
      email,
      ordernumber,
    })) as Response & { data: LoginResponse };
    if (res.status === 401) {
      throw new UnauthorizedError();
    }
    if (res.status === 404) {
      throw new NotFoundError();
    }
    if (!res.ok) throw Error("unexpected response");
    const json = await res.json();
    if (!json.data) throw new Error("unexpected reponse, data not present");
    return json.data;
  }

  public async jsonRequest(method: HttpMethod, path: string, body: Record<string, unknown>): Promise<Response> {
    return fetch(path, {
      method,
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
    });
  }
}

export const apiClientAnonymous = new ApiClientAnonymous();

/**
 * Thanks to Daniel Faber 🚀
 */
export class ApiClient {
  private constructor(
    private readonly authHeader: string | null,
    private readonly cookie: string | null,
    private readonly xAuthToken: string | null,
    private readonly returnDataObject: boolean = true,
  ) {}

  /**
   * @deprecated headers['Authorization'] does not work in Safari
   */
  public static withBearerToken(bearerToken: string): ApiClient {
    return new ApiClient(`Bearer ${bearerToken}`, null, null);
  }

  public static withCookie(cookie: string): ApiClient {
    return new ApiClient(null, cookie, null);
  }

  public static withXAuthToken(xAuthToken: string, returnDataObject = true): ApiClient {
    return new ApiClient(null, null, `${xAuthToken}`, returnDataObject);
  }

  private async jsonRequest<T>(
    method: HttpMethod,
    path: string,
    body: Record<string, unknown> | undefined,
  ): Promise<T> {
    const options: RequestInit & { headers: Record<string, string> } = {
      method,
      headers: {
        Authorization: this.authHeader ? this.authHeader : "",
        Cookie: this.cookie ? this.cookie : "",
        "x-auth-token": this.xAuthToken ? this.xAuthToken : "",
      },
    };
    if (body !== undefined) {
      options.headers["Content-Type"] = "application/json; charset=utf-8";
      options.body = JSON.stringify(body);
    }
    const res = await fetch(path, options);
    if (res.status === 401) {
      throw new UnauthorizedError();
    }
    if (!res.ok) {
      const text = await res.text().catch((e) => `failed to get text from response body: ${e}`);
      throw new Error(`request ${method} ${path} failed: statusCode = ${res.status}, body = ${text}`);
    }
    const json = await res.json();
    if (!this.returnDataObject) return json;
    else {
      if (!json.data) throw new Error("unexpected reponse, data not present");
      return json.data;
    }
  }

  private async textRequest(
    method: HttpMethod,
    path: string,
    body: Record<string, unknown> | undefined,
  ): Promise<string> {
    const options: RequestInit & { headers: Record<string, string> } = {
      method,
      headers: {
        Authorization: this.authHeader ? this.authHeader : "",
        Cookie: this.cookie ? this.cookie : "",
        "x-auth-token": this.xAuthToken ? this.xAuthToken : "",
      },
    };
    if (body !== undefined) {
      options.headers["Content-Type"] = "application/json; charset=utf-8";
      options.body = JSON.stringify(body);
    }
    const res = await fetch(path, options);
    if (res.status === 401) {
      throw new UnauthorizedError();
    }
    if (res.status === 409) {
      throw new ConflictError();
    }
    const text = await res.text();
    return text;
  }

  public async get<T>(path: string, pathId = ""): Promise<T> {
    const fullPath = path + pathId;
    return this.jsonRequest("GET", fullPath, undefined);
  }

  // eslint-disable-next-line
  public async post<T>(path: string, body: any): Promise<T> {
    return this.jsonRequest("POST", path, body);
  }

  // eslint-disable-next-line
  public async postTextRequest(path: string, body: any): Promise<string> {
    return this.textRequest("POST", path, body);
  }

  // eslint-disable-next-line
  public async put<T>(path: string, body: any): Promise<T> {
    return this.jsonRequest("PUT", path, body);
  }

  // eslint-disable-next-line
  public async delete<T>(path: string, body: undefined | any = undefined): Promise<T> {
    return this.jsonRequest("DELETE", path, body);
  }

  public async me(): Promise<LoginResponse> {
    return this.jsonRequest("GET", "/api/auth/me", undefined);
  }
}
