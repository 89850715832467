import Link from "next/link";
import React, { ButtonHTMLAttributes } from "react";
import { classNames } from "../../utils/helper";

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & { ref?: React.Ref<HTMLButtonElement> } & {
  variant: "primary" | "secondary" | "text" | "rainbow";
  size?: "sm" | "xs";
  isCircle?: boolean;
  isBlock?: boolean;
  href?: string;
  isExternalLink?: boolean;
};

const Button: React.FC<ButtonProps> = React.forwardRef(
  ({ href, children, variant, isCircle, isExternalLink, size, isBlock, ...props }, ref) => {
    const innerButton = (): JSX.Element => (
      <button
        ref={ref}
        {...props}
        className={classNames(
          variant === "primary"
            ? "bg-primary-500 hover:bg-primary-600  focus:ring-primary-600 disabled:bg-gray-200 focus:ring-2 "
            : "",
          variant === "secondary" ? "bg-black hover:bg-black  focus:ring-black disabled:bg-gray-200 focus:ring-2 " : "",
          variant === "rainbow"
            ? "bg-gradient-to-r from-purple-400 via-pink-500 to-red-500 disabled:bg-gray-200 focus:ring-2 "
            : "",
          variant === "text"
            ? "!text-black !shadow-none !text-left !justify-start focus:rounded-none focus:ring-transparent"
            : "",
          "flex justify-center items-center px-5 py-3 border border-transparent leading-4 font-bold rounded-full shadow-sm text-white focus:outline-none focus:ring-offset-2 transition-colors",
          size === "sm" ? "text-sm" : "",
          size === "xs" ? "text-xs" : "",
          isCircle ? "py-3 px-3" : "",
          isBlock ? "w-full" : "",
        )}
      >
        {children}
      </button>
    );
    if (href)
      return isExternalLink ? (
        <a href={href} target="_blank" rel="noreferrer">
          {innerButton()}
        </a>
      ) : (
        <Link href={href}>{innerButton()}</Link>
      );
    return innerButton();
  },
);

Button.displayName = "Button";

export { Button };
