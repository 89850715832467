import { Popover } from "@headlessui/react";
import { InformationCircleIcon } from "@heroicons/react/solid";
import React from "react";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface TooltipProps {}

export const Tooltip: React.FC<TooltipProps> = ({ children }) => {
  return (
    <Popover as="span" className="relative flex">
      <Popover.Button tabIndex={-1} className="focus:border-0 focus:outline-none">
        <InformationCircleIcon className="w-5 h-5 ml-2 text-gray-400" aria-hidden="true" />
      </Popover.Button>
      <Popover.Panel className="absolute z-10 bottom-10 -left-7">
        <div className="block p-4 text-sm bg-white rounded-md shadow-xl w-60">
          <div className="absolute origin-bottom-left transform rotate-45 bg-white top-2 h-11 w-11"></div>
          <div className="relative">{children}</div>
        </div>
      </Popover.Panel>
    </Popover>
  );
};
