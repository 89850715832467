import React, { useEffect } from "react";
import { ApiClient, UnauthorizedError } from "../../utils/ApiClient";
import { IS_APP_ENV_PRODUCTION } from "../../utils/constantsPublic";
import { useAuthDispatch } from "../contexts/AuthContext";
import { localStore } from "../localStore";

export const CheckAuth: React.FC = ({ children }) => {
  const dispatchAuthState = useAuthDispatch();

  useEffect(() => {
    // auto logout on tab close
    if (IS_APP_ENV_PRODUCTION) {
      window.addEventListener("beforeunload", function () {
        // unauthorized, log out
        localStore.deleteAuthToken();
        dispatchAuthState({ type: "logout" });
      });
    }

    (async () => {
      const authToken = localStore.getAuthToken();
      if (authToken !== undefined) {
        try {
          const apiClient = ApiClient.withXAuthToken(authToken);
          const user = await apiClient.me();
          dispatchAuthState({ type: "login", authToken, user });
        } catch (error) {
          if (error instanceof UnauthorizedError) {
            // unauthorized, log out
            localStore.deleteAuthToken();
            dispatchAuthState({ type: "logout" });
          }
        }
      } else {
        // no auth token in local storage
      }
    })();
  }, [dispatchAuthState]);
  return <>{children}</>;
};
