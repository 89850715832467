import React from "react";
import { NEXT_PUBLIC_COMMIT_HASH } from "../../utils/constantsPublic";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface FooterProps {}

export const Footer: React.FC<FooterProps> = ({}) => {
  return (
    <div className="self-end space-x-3 text-[9px] text-gray-300">
      <a className="focus:outline-none" href="https://www.ehrenkind.de/impressum/" target="_blank" rel="noreferrer">
        Impressum
      </a>
      <a
        className="focus:outline-none"
        href="https://www.ehrenkind.de/datenschutzerklaerung"
        target="_blank"
        rel="noreferrer"
      >
        Datenschutz
      </a>
      <a
        className="focus:outline-none"
        href="https://www.ehrenkind.de/shop/widerrufsbelehrung/"
        target="_blank"
        rel="noreferrer"
      >
        Widerrufsbelehrung
      </a>
      <a className="focus:outline-none" href="https://www.ehrenkind.de/shop/agb" target="_blank" rel="noreferrer">
        AGB
      </a>
      <span className="text-gray-50">
        {NEXT_PUBLIC_COMMIT_HASH}{" "}
        <a
          href="#"
          onClick={() => {
            throw new Error(`Frontend Error Test, ${new Date().toISOString()}`);
          }}
        >
          .
        </a>
      </span>
    </div>
  );
};
