export const style = {
  colorHeadline: "text-gray-900",
  colorNormal: "text-gray-600",
  colorMuted: "text-gray-400",
  colorPrimary: "text-primary-600",
  get title() {
    return `${this.colorHeadline} text-3xl font-extrabold`;
  },
  get headline1() {
    return `${this.colorHeadline} text-xl font-extrabold sm:text-2xl`;
  },
  get headline2() {
    return `${this.colorHeadline} text-xl font-bold`;
  },
  get headline3() {
    return `${this.colorHeadline} font-bold leading-5 lg:text-lg`;
  },
  get headline4() {
    return `${this.colorHeadline} font-medium leading-5 text-lg`;
  },
  get headlineMeta() {
    return `font-medium text-gray-500`;
  },
  get headlineBodyText() {
    return `${this.colorHeadline} font-medium`;
  },
  get paragraph() {
    return `${this.colorNormal}`;
  },
  get paragraphLink() {
    return "font-medium shadow-link focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-400";
  },
  get paragraphMargin() {
    return "mb-3";
  },
  get label() {
    return `font-medium ${this.colorNormal}`;
  },
} as const;
