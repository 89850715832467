import { ExclamationIcon, InformationCircleIcon } from "@heroicons/react/outline";
import React from "react";

interface AlertProps {
  variant: "error" | "warning" | "notice";
}

export const Alert: React.FC<AlertProps> = ({ children, variant }) => {
  if (variant === "error")
    return (
      <div className="p-5 border-l-4 border-primary-500 bg-red-50">
        <div className="flex">
          <div className="flex-shrink-0">
            <ExclamationIcon className="w-5 h-5 text-primary-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <div className="text-sm text-primary-700">{children}</div>
          </div>
        </div>
      </div>
    );
  if (variant === "notice")
    return (
      <div className="p-5 border-l-4 border-indigo-400 bg-gray-50">
        <div className="flex">
          <div className="flex-shrink-0">
            <InformationCircleIcon className="w-5 h-5 text-indigo-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <div className="text-sm text-gray-700">{children}</div>
          </div>
        </div>
      </div>
    );
  if (variant === "warning")
    return (
      <div className="p-5 border-l-4 border-yellow-400 bg-yellow-50">
        <div className="flex">
          <div className="flex-shrink-0">
            <ExclamationIcon className="w-5 h-5 text-yellow-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <div className="text-sm">{children}</div>
          </div>
        </div>
      </div>
    );
  else return <></>;
};
